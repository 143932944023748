.link {
  display: flex;
  align-items: center;
  height: 42px;
  width: 100%;
  padding-left: var(--mantine-spacing-md);
  padding-right: var(--mantine-spacing-md);;
  text-decoration: none;
  color: #000000;
  font-weight: 500;
  font-size: var(--mantine-font-size-sm);

  &:hover {
    background-color: light-dark(
      var(--mantine-color-gray-0),
      var(--mantine-color-dark-7)
    );
  };
};

.userProfile {
  margin-top: var(--mantine-spacing-xl);
  padding-left: var(--mantine-spacing-md);
  padding-right: var(--mantine-spacing-md);
};
