.root {
  padding: 3rem,
};

.value {
  font-size: 24px;
  font-weight: 700;
  line-height: 1;
};

.description {
  line-height: 1;
  display: flex;
  align-items: center;
};

.icon {
  color: var(--mantine-color-gray-4);
};

.title {
  font-weight: 700;
  text-transform: uppercase;
};
