@import "src/styles";

.ContentContainer {
  max-width: 1200px; // set the maximum width
  margin-left: auto; // center the container on the page
  margin-right: auto;
  width: 100%; // make the container responsive
  height: 100%;

  // Media queries for different screen sizes
  @media (max-width: 768px) {
    // styles for tablets
    max-width: 90%;
  }
  @media (max-width: 480px) {
    // styles for phones
    max-width: 95%;
  }
}
