@import 'variables';

// Responsive
@mixin laptop-media-breakpoint {
  @media (max-width: $laptop-width) {
    @content
  }
}

@mixin medium-media-breakpoint {
  @media (max-width: $medium-desktop-width) {
    @content
  }
}

@mixin tablet-media-breakpoint {
  @media (max-width: $tablet-width) {
    @content
  }
}

@mixin mobile-media-breakpoint {
  @media (max-width: 540px) {
    @content
  }
}

// Card
@mixin card-border {
  display: flex;
  border: 1px solid $grey-30-color;
}

@mixin flex-column {
  display: flex;
  flex-flow: column;
}

@mixin flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin flex-row {
  display: flex;
  flex-direction: row;
}

// Layout
@mixin content-container {
  width: 100%;
  padding: 0 16px;
  margin: 0 auto;

  @media (min-width: 576px) {
    max-width: 540px;
  }
  @media (min-width: 768px) {
    max-width: 720px;
  }
  @media (min-width: 992px) {
    max-width: 960px;
  }
  @media (min-width: 1200px) {
    max-width: 1140px;
  }
  @media (min-width: 1400px) {
    max-width: 1320px;
  }
}

@mixin grid-child-positioning ($children-amount) {
  display: grid;
  @for $i from 2 through $children-amount {
    & > :nth-child(#{$i}) {
      grid-row: $i + $i - 1;
    }
  }
}
@mixin grid-child-positioning-column ($children-amount) {
  display: grid;
  @for $i from 2 through $children-amount {
    & > :nth-child(#{$i}) {
      grid-column: $i + $i - 1;
    }
  }
}

@mixin pseudo-content {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 100%;
}

@mixin get-box($size) {
  width: $size;
  height: $size;
}

@mixin small-typography {
  font-size: 18px;
  line-height: 32px;
}

@mixin padding-vertical($size) {
  padding-top: $size;
  padding-bottom: $size;
}
