.link {
  display: flex;
  align-items: center;
  height: 100%;
  padding-left: 16px;
  padding-right: 16px;
  text-decoration: none;
  color: #000000;
  font-weight: 500;
  font-size: var(--mantine-font-size-sm);

  &:hover {
    background-color: var(--mantine-color-gray-0)
  };
};

.hiddenMobile {
  @media (max-width: 48em) {
    display: none
  }
};

.hiddenDesktop {
  @media (min-width: 48em) {
    display: none;
  }
};
