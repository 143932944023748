.root {
  padding-top: 120px;
  padding-bottom: 120px;
  background-color: #FFFFFF;
  height: 100vh;
};

.inner {
  position: relative;
};

.image {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 0;
  opacity: 0.65;
};

.content {
  padding-top: 220px;
  position: relative;
  z-index: 1;

  @media (max-width: 48em) {
    padding-top: 120px;
  }
};

.title {
  font-family: 'Greycliff CF', serif;
  text-align: center;
  font-weight: 900;
  font-size: 38px;
  color: #3B3B3B;

  @media (max-width: 48em) {
    font-size: 32px;
  }
};

.description {
  max-width: 460px;
  margin: var(--mantine-spacing-xl) auto 3rem;
  color: #C9C9C9;
};
