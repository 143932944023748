.th {
  padding: 0 !important;
};

.control {
  width: 100%;
  padding: 0.625rem 1rem;
  background-color: #f8f9fb;

  &:hover {
    background-color: #F8F9FA;
  };
};

.icon {
  width: 21px;
  height: 21px;
  border-radius: 21px;
};
