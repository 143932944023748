@import 'src/styles';

.AuthLayout {
  display: grid;
  grid-template-columns: 1fr 3fr;
  height: 100vh;
  width: 100%;

  @include medium-media-breakpoint {
    @include flex-column;
    padding: 0 16px;
    height: 100%;
  }

  &__creative-container {
    background: url('https://gpa-factors.s3.us-west-2.amazonaws.com/gpa_auth.jpeg') center;
    background-size: cover;
  }

  &__form-container {
    display: grid;
    grid-template-columns: 25% minmax(50%, 540px) 25%;
    justify-content: center;
    align-items: center;

    & > :first-child {
      grid-column: 2;
    }

    @include tablet-media-breakpoint {
      @include flex-column;
      padding: 0 16px;
    }

    @include mobile-media-breakpoint {
      padding: 0;
    }
  }
}
