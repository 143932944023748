@import 'src/styles';

$--brand-primary: #2f70c1;
$--brand-secondary: #7461c3;

.EmblaCarousel {
  --slide-spacing: 1rem;
  --slide-size: 100%;
  --slide-height: 19rem;
  padding: 1.6rem;

  @include mobile-media-breakpoint {
    padding: 0;
  }

  position: relative;
  &__viewport {
    overflow: hidden;
  }

  &__container {
    display: flex;
    flex-direction: column;
    height: calc(var(--slide-spacing) + var(--slide-height));
    //margin-top: calc(var(--slide-spacing) * -1);

    @include mobile-media-breakpoint {
      height: calc(var(--slide-spacing) + 40rem);
    }
  }

  &__slide {
    //flex: 0 0 var(--slide-size);
    flex: 0 0 100%;
    //min-height: 0;
    //padding-top: var(--slide-spacing);
    position: relative;
  }

  &__slide-number {
    background-image: linear-gradient(-60deg, #3BC9DB 0%, #1098AD  100%);

    z-index: 1;
    position: absolute;
    top: calc(var(--slide-spacing) + 0.6rem);
    right: 0.6rem;
    font-family: 'Greycliff CF', serif;
    text-align: center;
    pointer-events: none;
  }

  &__slide-number > span {
    color: var(--brand-primary);
    background-image: linear-gradient(
                    45deg,
                    var(--brand-primary),
                    var(--brand-secondary)
    );
    background-clip: text;
    //-webkit-background-clip: text;
    //-webkit-text-fill-color: transparent;
    font-size: 1.6rem;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  &__progress {
    z-index: 1;
    background-color: var(--background-site);
    position: absolute;
    height: 0.3rem;
    border-radius: 0.2rem;
    left: 0;
    right: 0;
    bottom: 2.6rem;
    margin-left: auto;
    margin-right: auto;
    pointer-events: none;
    width: 21rem;
    max-width: 90%;
    overflow: hidden;
  }
  &__progress-bar {
    transition: all 50ms ease-in;
    background-image: linear-gradient(-60deg, #3bc9db 0%, #1098ad 100%);
    position: absolute;
    width: 100%;
    top: 0;
    bottom: 0;
    left: -100%;
  }
}